import { Route, Routes, useLocation } from "react-router-dom"
import { ColorModeContext, useMode } from "../../theme.js"
import { CssBaseline, ThemeProvider, useMediaQuery } from "@mui/material"
import { lazy, Suspense, useEffect, useReducer } from "react"
import Home from '../../pages/home/index.jsx'
import Menu from "../Menu/index"
import MobileMenu from "../MobileMenu"
import LoadingSpinner from "../Loader"
import MultiFactorAuthentication from "../../pages/network-and-cybersecurity/09-mfa.jsx"
import ManagedServicesLosAngeles from "../../pages/los-angeles/managed-it-servces.jsx"

const Footer = lazy(() => import('../Footer'))
const OurStory = lazy(() => import("../../pages/our-story/index.jsx"))
const OurMission = lazy(() => import("../../pages/our-mission/index.jsx"))
const Support = lazy(() => import("../../pages/support/index.jsx"))
const Contact = lazy(() => import("../../pages/contact/index.jsx"))
const Careers = lazy(() => import("../../pages/careers/index.jsx"))
const PageNotFound = lazy(() => import("../PageNotFound/index.jsx"))
const TermsOfUse = lazy(() => import("../../pages/terms/index.jsx"))
const PrivacyPolicy = lazy(() => import("../../pages/privacy/index.jsx"))
const Cookies = lazy(() => import("../../pages/cookies/index.jsx"))

/* Blog */
const BlogHome = lazy(() => import("../../pages/blog/index.jsx"))
const Blog = lazy(() => import("../Blog"))

/* IT Solutions */
const ITSolutions = lazy(() => import("../../pages/it-solutions/index.jsx"))
const CloudServices = lazy(() => import("../../pages/it-solutions/01-cloud.jsx"))
const BusinessContinuity = lazy(() => import("../../pages/it-solutions/02-bcdr.jsx"))
const VoiceOverInternetProtocol = lazy(() => import("../../pages/it-solutions/03-voip.jsx"))
const ManagedPrintServices = lazy(() => import("../../pages/it-solutions/04-print.jsx"))
const InternetConnectivity = lazy(() => import("../../pages/it-solutions/05-internet.jsx"))
const StructuredCablingServices = lazy(() => import("../../pages/it-solutions/06-cabling.jsx"))
const TechnologyProcurement = lazy(() => import("../../pages/it-solutions/07-procurement.jsx"))

/* Managed IT Services */
const ManagedServices = lazy(() => import('../../pages/managed-it-services/index.jsx'))
const UnlimitedTechnicalSupport = lazy(() => import("../../pages/managed-it-services/01-unlimited.jsx"))
const RemoteMonitoring = lazy(() => import("../../pages/managed-it-services/02-remote.jsx"))
const OnSite = lazy(() => import("../../pages/managed-it-services/03-onsite.jsx"))
const WindowsPatching = lazy(() => import("../../pages/managed-it-services/04-patching.jsx"))
const BusinessTechnologyAudit = lazy(() => import("../../pages/managed-it-services/05-audit.jsx"))
const Analytics = lazy(() => import("../../pages/managed-it-services/06-analytics.jsx"))

/* Network & Cybersecurity */
const Cybersecurity = lazy(() => import("../../pages/network-and-cybersecurity/index.jsx"))
const EndpointDetectionResponse = lazy(() => import("../../pages/network-and-cybersecurity/01-edr.jsx"))
const ManagedSecurityOperationsCenter = lazy(() => import("../../pages/network-and-cybersecurity/02-soc.jsx"))
const VulnerabilityManagement = lazy(() => import("../../pages/network-and-cybersecurity/03-vulnerability.jsx"))
const PenetrationTesting = lazy(() => import("../../pages/network-and-cybersecurity/04-penetration.jsx"))
const SecurityAwarenessTraining = lazy(() => import("../../pages/network-and-cybersecurity/05-awareness.jsx"))
const MobileDeviceManagement = lazy(() => import("../../pages/network-and-cybersecurity/06-mdm.jsx"))
const EncryptedEmail = lazy(() => import("../../pages/network-and-cybersecurity/07-encrypted.jsx"))
const EmailThreatDetection = lazy(() => import("../../pages/network-and-cybersecurity/08-email.jsx"))

const App = () => {

  const [theme, colorMode] = useMode()
  const isDesktop = useMediaQuery(theme.breakpoints.up('laptop'))
  
  const [formState, dispatch] = useReducer(setState, '')

  const setFormState = (type) => {
    dispatch({type})
  }

  const { pathname } = useLocation()

  useEffect(() => {
    if (pathname !== '/contact') {
      setFormState('')
    }
  }, [pathname])

  return (
    <Suspense fallback={<LoadingSpinner />}>
    <ColorModeContext.Provider value={colorMode}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="app">
        <div id="back-to-top-anchor"></div>
        {isDesktop ? <Menu onChangeFormState={setFormState} /> : <MobileMenu onChangeFormState={setFormState} />}
        <main id='main' className="content">
          <Routes>
            {/* GENERAL */}
            <Route index element={<Home />} />
              <Route path="our-mission" element={<OurMission />} />
              <Route path="our-story" element={<OurStory />} />
              <Route path="contact" element={<Contact formState={formState} onChangeFormState={setFormState} />} />
              <Route path="careers" element={<Careers />} />
              <Route path="support" element={<Support />} />
              <Route path="terms" element={<TermsOfUse />} />
              <Route path="privacy" element={<PrivacyPolicy />} />
              <Route path="cookies" element={<Cookies />} />

            {/* IT Solutions */}
              <Route path="it-solutions" element={<ITSolutions />} />
              <Route path="cloud-services" element={<CloudServices />} />
              <Route path="business-continuity-and-disaster-recovery" element={<BusinessContinuity />} />
              <Route path="voip" element={<VoiceOverInternetProtocol />} />
              <Route path="managed-print-services" element={<ManagedPrintServices />} />
              <Route path="internet-connectivity" element={<InternetConnectivity />} />
              <Route path="structured-cabling-and-electrical-services" element={<StructuredCablingServices />} />
              <Route path="technology-procurement-services" element={<TechnologyProcurement />} />

              {/* Managed IT Services */}
              <Route path="managed-it-services" element={<ManagedServices />} />
              <Route path="unlimited-technical-support" element={<UnlimitedTechnicalSupport />} />
              <Route path="remote-monitoring-and-management" element={<RemoteMonitoring />} />
              <Route path="on-site-it-support-and-network-services" element={<OnSite/>}/>
              <Route path="windows-updates-and-third-party-patching" element={<WindowsPatching />} />
              <Route path="business-technology-audits" element={<BusinessTechnologyAudit />} />
              <Route path="analytics-and-reporting" element={<Analytics />} />

              {/* Network & Cybersecurity */}
              <Route path="network-and-cybersecurity" element={<Cybersecurity />} />
              <Route path="endpoint-threat-detection-and-response" element={<EndpointDetectionResponse />} />
              <Route path="managed-security-operations-center" element={<ManagedSecurityOperationsCenter />} />
              <Route path="vulnerability-management" element={<VulnerabilityManagement />} />
              <Route path="virtual-network-penetration-testing" element={<PenetrationTesting />} />
              <Route path="security-awareness-training" element={<SecurityAwarenessTraining />} />
              <Route path="mobile-device-management" element={<MobileDeviceManagement />} />
              <Route path="encrypted-email" element={<EncryptedEmail />} />
              <Route path="advanced-email-threat-protection" element={<EmailThreatDetection />} />
              <Route path="multi-factor-authentication" element={<MultiFactorAuthentication />} />

              {/* Blog */}
              <Route path="blog">
                <Route index element={<BlogHome />} />
                <Route path=':path' element={<Blog />} />
              </Route>

              {/* Los Angeles*/}
              <Route path="managed-it-services-in-los-angeles" element={<ManagedServicesLosAngeles />} />
            

            {/* Routing */}
            <Route path="404" element={ <PageNotFound status={404} path={pathname} /> } />
            <Route path="*" element={ <PageNotFound status={404} path={pathname} />} />
          </Routes>
          <Footer />
        </main>
      </div>
    </ThemeProvider>
    </ColorModeContext.Provider>
    </Suspense>
  )
}

const setState = (formState, action) => {
  if (action.type === 'error') {
    return 'Error'
  } else if (action.type === 'confirm') {
    return 'Confirm'
  } else {
    return ''
  }
}

export default App
