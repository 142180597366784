import Seo from '../../components/Seo'
import ScrollToTop from '../../components/buttons/ScrollToTop'
import ServiceNavMenu from '../../components/ServiceNavMenu'
import { AiOutlineAudit } from "react-icons/ai" /* audit */
import { IoAnalyticsSharp } from "react-icons/io5" /* analytics & reporting */
import { FaWindows } from "react-icons/fa6"
import { BiSupport } from "react-icons/bi" /* Unlimited Technical Support */
import { BsPersonGear } from "react-icons/bs" /* On-Site & Network Services */
import { PiGauge } from "react-icons/pi" /* Remote Monitoring */
import './index.css'
import Solutions from '../../components/Solutions'

const ManagedServicesLosAngeles = (props) => {
  return (
    <div className='page'>
      <Seo 
        title="Managed IT Services in Los Angeles - 7 Layer IT Solutions"
        description="Fully Managed IT Services in Los Angeles. Expert IT Management for Business and Non-Profit Organizations."
        keywords="it services los angeles, managed it services los angeles, it solutions los angeles, expert it services los angeles, it support los angeles, it services for business, los angeles it outsourcing, it outsourcing los angeles"
        type="website"
        name="7 Layer IT Solutions"
        url="https://www.7lits.com/managed-it-services-in-los-angeles"
      />
      <div className='los-angeles-page-container'>
        <div className='los-angeles-page-title'>
          <h1>Managed IT Services in Los Angeles</h1>
        </div>
        <div className='los-angeles-contents'>
          <p>Are you struggling with unpredictable IT costs and frequent technical issues? Do you want a trusted managed IT services provider that proactively manages your IT infrastructure and ensures seamless operations?</p>
          <p>At 7 Layer IT Solutions, we offer comprehensive managed IT services in Los Angeles, CA, designed to provide cost-effective, secure, and scalable IT solutions. Whether you&apos;re a small business or a large enterprise, our managed IT support services ensure zero downtime, 24/7 monitoring, and expert technical support all at a fixed monthly cost with no hidden fees.</p>        
        </div>
        <div className='los-angeles-contents'>
          <h2>Why Choose Our Managed IT Services in Los Angeles?</h2>
          <p>Our managed IT services in Los Angeles include:</p>
          <ul>
            <li>
              <h3>Predictable IT Costs</h3>
              <p>Say goodbye to fluctuating IT bills. Our Los Angeles-managed services come with an all-inclusive, fixed monthly fee.</p>
            </li>
            <li>
              <h3>Proactive IT Management</h3>
              <p>Our team monitors your network 24/7, identifying and fixing issues before they impact your business. No more waiting for problems to arise.</p>
            </li>
            <li>
              <h3>Full-Service IT Solutions</h3>
              <p>From cybersecurity and cloud computing to network infrastructure and IT support, we cover every aspect of your IT needs.</p>
            </li>
            <li>
              <h3>Managed IT Support & Consulting</h3>
              <p>Get expert IT consulting without extra charges. Our managed IT support services are designed to align with your business goals and boost efficiency.</p>
            </li>
            <li>
              <h3>Advanced Security & Compliance</h3>
              <p>Protect your business with cutting-edge cybersecurity, data encryption, and compliance solutions that safeguard your operations.</p>
            </li>
            <li>
              <h3>Scalable Managed IT Services</h3>
              <p>Whether you&apos;re expanding your team, launching new projects, or navigating digital transformation, our flexible, cost-effective IT solutions adapt to your evolving needs. </p>
            </li>
          </ul>
        </div>
        <div className='los-angeles-contents'>
          <h2>Los Angeles IT Outsourcing for Business & Non-Profit Organizations</h2>
          <p>We specialize in managed IT services in Los Angeles, serving businesses with reliable IT support, proactive security, and affordable IT solutions. Our expertise in network security, cloud integration, and IT infrastructure ensure your business is secure, productive, and working ahead of the competition.</p>        
        </div>
      </div>
      
      <ServiceNavMenu data={data} />
      <Solutions />
      <ScrollToTop {...props} />
    </div>
    )
  }

const data = [
  {
    "name": "Unlimited Technical Support",
    "url": "/unlimited-technical-support",
    "icon": <BiSupport fontSize={32} />
  },
  {
    "name": "Remote Monitoring & Management",
    "url": "/remote-monitoring-and-management",
    "icon": <PiGauge fontSize={32} />
  },
  {
    "name": "On-Site IT & Network Services",
    "url": "/on-site-it-support-and-network-services",
    "icon": <BsPersonGear fontSize={32} />
  },
  {
    "name": "Windows Patching & Updates",
    "url": "/windows-updates-and-third-party-patching",
    "icon": <FaWindows fontSize={32}/>
  },
  {
    "name": "Business Technology Audits",
    "url": "/business-technology-audits",
    "icon": <AiOutlineAudit style={{fontSize:32}}/>
  },
  {
    "name": "Analytics & Reporting",
    "url": "/analytics-and-reporting",
    "icon": <IoAnalyticsSharp style={{fontSize:32}}/>
  }
]

export default ManagedServicesLosAngeles