import { useMediaQuery, useTheme } from '@mui/material'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import './index.css'

const ServiceNavMenu = ({ data }) => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('laptop'))
  return (
    isDesktop && <>
      <div className='divider'></div>
      <div className='it-services-nav'>
        {data.map((service, index) => {
          return (
            <Link to={service.url} key={index}>
              <div className='service-button'>
                <div className='icon-container'>
                  {service.icon}
                </div>
                <h2>{service.name}</h2>
              </div>
            </Link>
          )
        })}
      </div>
    </>
  )
}

ServiceNavMenu.propTypes = {
  data: PropTypes.array.isRequired
}

export default ServiceNavMenu